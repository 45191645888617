export default {
  'hero.title': 'MindTech – ваш надежный партнер в мире технологий и инноваций.',
  'hero.slogan':
    'Наша компания была основана в 2018 году в Минске, и с тех пор мы стремимся предоставлять качественные и комплексные ИТ-решения для различных отраслей бизнеса.',
  'hero.button': 'Узнать больше',
  'about.title':
    'Мы специализируемся на оказании услуг IT-поддержки, гарантируя бесперебойную работу вашего бизнеса.',
  'about.text':
    'Наша команда профессионалов обеспечивает оперативное реагирование на технические проблемы и предоставляет поддержку на всех этапах внедрения и использования информационных технологий.',
  'about.text2':
    'Мы резиденты Парка высоких технологий. Наша цель — создавать продукцию высочайшего качества с использованием современных технологий и решений. В нашей команде более 100 профессионалов. Целенаправленный подбор персонала позволяет нам не только иметь команду профессионалов, но и растить и развивать молодых специалистов с высоким потенциалом.',
  'about.button': 'Свяжитесь с нами',
  'service.title': 'Мы обеспечиваем',
  'service.title2': 'Мы создаем',
  'service.text':
    'Наша компания – это команда высококвалифицированных профессионалов. Мы используем передовые технологии там, где они оправданы и приносят несомненную выгоду.',
  'service.text2':
    'Мы ценим каждого сотрудника, и мы стараемся обеспечить каждого члена нашей команды всем необходимым, учитывая индивидуальные пожелания.',

  'portfolio.adm.title': 'Системное администрирование',
  'portfolio.adm.text':
    'Наши администраторы решают сложные задачи, такие как развертывание кластерной системы, оптимизация нагрузки и обеспечение высокой доступности. Они также держат все процессы на серверах под полным контролем. Мы предпочитаем бесплатное программное обеспечение с открытым исходным кодом, ОС — Ubuntu и Debian. DDOS-атаки нас не пугают, и мы знаем, как с ними справиться.',
  'portfolio.mobile.title': 'Мобильные приложения',
  'portfolio.mobile.text':
    'Javascript/HTML5 и нативные приложения для iOS и Android позволяют нашим клиентам использовать приложения с любого доступного в данный момент устройства. Мы стремимся сделать приложения оптимальными в использовании ресурсов устройств и использовать как можно меньше пропускной способности. Мы адаптируем интерфейсы как для телефонов, фаблетов, так и для карманных устройств.',
  'portfolio.java.title': 'Java',
  'portfolio.java.text':
    'Java лучше всего подходит для полноценных серверных служб, веб-сайтов, работы с базами данных и многопоточных приложений. По нашему мнению, лучший пользовательский интерфейс — это тот, который прост в использовании, быстро усваивается и не содержит ненужных или лишних элементов. Для внешней разработки используется мощная комбинация Angular и REST API.',
  'cta.title': 'Вы готовы масштабировать свой бизнес?',
  'cta.text': 'Свяжитесь с нами и давайте вместе построим что-то потрясающее!',
  'footer.title.address': 'наш АДРЕС',
  'footer.address': 'Минск, ул. Петра Мстиславца, 13-268',
  'footer.title.phone': 'свяжитесь с  НАМи',
  'footer.title.email': 'ОТПРАВИТЬ E-MAIL',
  copyright: '© {0} MindTech. Все права защищены.',
};
