export default {
  'hero.title': 'MindTech is your reliable partner in the world of technology and innovation.',
  'hero.slogan':
    'Our company was founded in 2018 in Minsk, and since then we have been committed to providing high-quality and integrated IT solutions for various business sectors.',
  'hero.button': 'Learn more',
  'about.title':
    'We specialize in providing IT support services, ensuring the smooth operation of your business.',
  'about.text':
    'Our team of professionals ensures prompt response to technical issues and provides support at all stages of IT implementation and utilization.',
  'about.text2':
    'We are residents of the High Technology Park. Our goal is to create the highest-quality products using modern technologies and solutions. Our team consists of over 100 professionals. A targeted personnel selection enables us to have not only a team of professionals but also to grow and develop young professionals with high potential.',
  'about.button': 'Contact us',
  'service.title': 'We provide',
  'service.title2': 'We Make',
  'service.text':
    'Our company is a team of highly trained professionals. We use cutting-edge technologies where they are justified and add unquestionable gain.',
  'service.text2':
    'Each employee is valued, and we try our best to supply each and every member of our team with everything needed, taking individual requests into consideration.',
  'portfolio.adm.title': 'System Administration',
  'portfolio.adm.text':
    'Our administrators handle complex tasks such as cluster system deployment, load optimization, and high availability. They also keep all processes on the servers under full control. We prefer free software with open source code, OS - Ubuntu and Debian. DDOS attacks don’t scare us, and we know how to handle them.',
  'portfolio.mobile.title': 'Mobile Applications',
  'portfolio.mobile.text':
    'Javascript/HTML5 and native applications for iOS and Android allow our customers to use applications from any currently available device. We strive to make the applications optimal in using devices resources and use the least amount of bandwidth. We adapt interfaces for both phones, phablets and handheld devices.',
  'portfolio.java.title': 'Java',
  'portfolio.java.text':
    'Java is best equipped for full-fledged backend services, web sites, working with databases, and multithreaded applications. In our opinion, the best user interface is one that is simple to use, quick to grasp, and free of any unnecessary or excess elements. A powerful combination of Angular and REST API is used for front-end development.',
  'cta.title': 'Are you ready to scale your business?',
  'cta.text': 'Get in touch and let us build something amazing together!',
  'footer.title.address': 'OFFICE ADDRESS',
  'footer.address': 'Minsk, Peter Mstislavets street 13-268',
  'footer.title.phone': 'CALL US',
  'footer.title.email': 'SEND EMAIL',
  copyright: '© {0} MindTech. All rights reserved.',
};
