import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../Navbar/NavLinks';
import { useLocaleContext, locales } from '../../hooks/localisation';
import Logo from '../../images/logo.svg';

const NavBar = ({ useMenu = true }) => {
  const [top, setTop] = useState(true);
  const [isOpen, setisOpen] = React.useState(false);
  const { toggleLocale, locale } = useLocaleContext();
  console.log('L', locale, locales);

  function handleClick() {
    setisOpen(!isOpen);
  }

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  return (
    <nav
      className={`fixed w-full top-0 right-0 z-30 transition duration-300 ease-in-out mb-16 ${
        !top && 'bg-white shadow-lg'
      }`}
    >
      <div className='flex flex-row justify-between items-center px-4 py-4 md:px-6'>
        <div className='flex flex-row justify-center items-center text-center font-semibold'>
          <Link to='/'>
            <div className='inline-block md:hidden'>
              <img src={Logo} width={150} alt='mind-tech' />
            </div>
            <div className='inline-block hidden md:inline-block'>
              <img src={Logo} width={200} alt='mind-tech' />
            </div>
          </Link>
        </div>

        <button
          className='rounded border-2  px-2 py-1 border-accent-hover text-accent'
          onClick={toggleLocale}
        >
          {locale === locales.en ? locales.ru : locales.en}
        </button>

        {useMenu && (
          <div className='group flex flex-col items-center'>
            <button className='p-2 rounded-lg lg:hidden text-accent' onClick={handleClick}>
              <svg
                className='h-6 w-6 fill-current'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
              >
                {isOpen && (
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z'
                  />
                )}
                {!isOpen && (
                  <path
                    fillRule='evenodd'
                    d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z'
                  />
                )}
              </svg>
            </button>
            <div className='hidden space-x-6 lg:inline-block p-5'>
              <NavLinks />
            </div>

            <div
              className={`fixed transition-transform duration-300 ease-in-out transit flex justify-center left-0 w-full h-auto rounded-md p-24 bg-white rounded-lg block lg:hidden shadow-xl top-14 ${
                isOpen ? 'block' : 'hidden'
              } `}
            >
              <div className='flex flex-col space-y-6'>
                <NavLinks />
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
