export const contacts = {
  phone: {
    link: 'tel:+375293305896',
    text: '+375 (29) 330-58-96 ',
  },
  email: {
    link: 'mailto:info@mindtech.by',
    text: 'info@mindtech.by',
  },
};
